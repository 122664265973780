export const recordType = 'distribution_list';

export const DistributionListColumns = [
	{
		headerName: 'Name',
		field: 'name',
		flex: 1,
		sortable: true,
		minWidth: 150,
	},
	{
		headerName: 'Labels',
		field: 'labels',
		type: 'labels',
		minWidth: 200,
	},
	{
		headerName: 'Created by',
		field: 'created_by_user',
		minWidth: 150,
		type: 'reference',
		referenceType: 'user',
	},
	{
		headerName: 'Updated by',
		field: 'updated_by_user',
		minWidth: 150,
		type: 'reference',
		referenceType: 'user',
	},
	{
		headerName: 'Created at',
		field: 'created_at',
		type: 'datetime',
		sortable: true,
		minWidth: 120,
	},
	{
		headerName: 'Updated at',
		field: 'updated_at',
		type: 'datetime',
		sortable: true,
		minWidth: 120,
	},
];
