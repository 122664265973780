// Dependencies
import React from 'react';
// ant design & Icons
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// utils
import { referenceDataUrl } from 'src/utils/api-prefix.helper';
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';

export default {
	referenceRecord: 'location-group',
	groupOf: 'location',
	referenceData: true,
	navigateByCode: true,
	icon: (
		<div className="fa-layers fa-fw">
			<FontAwesomeIcon icon={faMapMarkerAlt} transform="up-2 left-3" />
			<FontAwesomeIcon icon={faMapMarkerAlt} transform="shrink-4 down-6 right-6" />
		</div>
	),
	url: `${referenceDataUrl}location-group`,
	urlParams: 'relation=[locations,user,location_alias,direct_parent]',
	elasticSearchKey: 'Location',
	relatedReferences: [],
	keysMatching: [],
	path: '/locations-group',
	pageTitle: {
		list: 'Location groups',
		action: {
			create: 'New location group',
			edit: 'Edit location group',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
			type: 'textWithIcon',
			iconName: 'Pending',
			color: '#208EC3',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			valueGetter: ({ data }: any) => data.direct_parent?.name || data.direct_parent?.code,
			cellRenderer: ({ data }: any) => {
				return data.direct_parent ? (
					<ReferenceField
						value={data.direct_parent.name}
						url={`/${data.direct_parent.is_group ? 'locations-group' : 'locations'}/${
							data.direct_parent.code
						}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'Locations',
			field: 'relations',
			cellRenderer: ({ data }: any) => {
				return (data.relations || []).map(
					(reference: { name: string; code: string; deleted_at: string }, i: number) => (
						<ReferenceField
							disabled={reference.deleted_at}
							key={`reference${i}`}
							value={reference.name}
							url={`/locations/${reference.code}`}
						/>
					)
				);
			},
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	parentField: 'parent_id',
};
