import DataService from 'src/utils/DataService';
import ConfigReport from 'ui/modules/Reporting/config/reporting.config';
import { IReport } from 'ui/modules/Reporting/types/interfaces/report.interface';

export const getInstance = () => {
	return new DataService({
		url: `${ConfigReport.url}`,
		urlParams: '',
	});
};

export const getOne = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}/`,
		urlParams: '',
	}).getClean(id);
};

export const createOne = async (payload: IReport) => {
	return await new DataService({
		url: `${ConfigReport.url}`,
		urlParams: '',
	}).create(payload);
};

export const updateOne = async (id: string, payload: IReport) => {
	return await new DataService({
		url: ConfigReport.url,
		urlParams: '',
	}).update(id, payload);
};

export const deleteOne = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}`,
		urlParams: '',
	}).delete(id);
};

// TODO: api not implemented yet
export const deleteBulk = async (payload: { ids: string[] }) => {
	return await new DataService({
		url: `${ConfigReport.url}/delete`,
		urlParams: '',
	}).post(payload);
};

export const getDestributionAnalysis = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/analysis`,
		urlParams: '',
	}).getClean();
};

const ReportingService = {
	getInstance,
	getOne,
	createOne,
	updateOne,
	deleteOne,
	deleteBulk,
	getDestributionAnalysis,
};

export default ReportingService;
