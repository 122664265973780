// service
import DataService from 'src/utils/DataService';
// utils
import { reporting, getApiPrefix, server, members } from 'src/utils/api-prefix.helper';
// Interfaces
import { IMember } from 'ui/modules/Reporting/types/interfaces/member.interface';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;
const membersUrl = `${serverUrl}${reporting}/${members}`;

export const getInstance = (urlSuffix: string = '') => {
	return new DataService({
		url: `${membersUrl}${urlSuffix}`,
		urlParams: '',
		token: true,
	});
};

export const getAll = () => {
	return getInstance().getAll();
};

export const getOne = (id: string) => {
	return getInstance().getClean(id);
};

export const createOne = (payload: IMember) => {
	return getInstance().create(payload);
};

export const updateOne = (id: string, payload: IMember) => {
	return getInstance().update(id, payload);
};

export const bulkDelete = async (payload: { ids: string[] }) => {
	return getInstance('/delete').post(payload);
};

const MemberService = {
	getInstance,
	getAll,
	getOne,
	createOne,
	updateOne,
	bulkDelete,
};

export default MemberService;
