// service
import DataService from 'src/utils/DataService';
// utils
import { reporting, getApiPrefix, server, distributionList } from 'src/utils/api-prefix.helper';
import { IDistributionList } from 'ui/modules/Reporting/types/interfaces/distribution-list.interface';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;
const distributionListUrl = `${serverUrl}${reporting}/${distributionList}`;

export const getInstance = (urlSuffix: string = '') => {
	return new DataService({
		url: `${distributionListUrl}${urlSuffix}`,
		urlParams: '',
		token: true,
	});
};

export const getAll = () => {
	return getInstance().getAll();
};

export const getOne = (id: string) => {
	return getInstance().getClean(id);
};

export const createOne = (payload: IDistributionList) => {
	return getInstance().create(payload);
};

export const updateOne = (id: string, payload: IDistributionList) => {
	return getInstance().update(id, payload);
};

export const bulkDelete = async (payload: { ids: string[] }) => {
	return getInstance('/delete').post(payload);
};

const DistributionListService = {
	getInstance,
	getAll,
	getOne,
	createOne,
	updateOne,
	bulkDelete,
};

export default DistributionListService;
