// Dependencies
import React from 'react';
//constants
import NavigationButton from 'ui/modules/Fundamentals/components/NavigationButton/NavigationButton';
// Shared Components
import { DistributionListColumns } from 'ui/modules/Reporting/constants/distribution-list.constant';
//services
import DistributionListService from 'ui/modules/Reporting/services/distribution-list.service';

export default {
	referenceData: false,
	iconName: 'DistributionList',
	path: '/distribution-list',
	pageTitle: {
		list: 'Distribution Lists',
		action: {
			create: 'New Distribution List',
			edit: 'Edit Distribution List',
		},
	},
	columns: DistributionListColumns,
	actions: [<NavigationButton recordType="member" />],
	getService: () => ({
		instance: DistributionListService.getInstance,
		bulkDelete: DistributionListService.bulkDelete,
	}),
	canChangeTags: true,
};
