// Helpers
import {
	getApiPrefix,
	basePath,
	dev,
	server,
	beta,
	data,
	storage,
	dataManagement,
	dataPrep,
} from 'src/utils/api-prefix.helper';
// configs
import configRecords from 'src/configRecords';
import configInterface from 'src/interface.config';
import configDefaultFields from 'src/defaultFields.config';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;
const isDnexr = getApiPrefix() === beta;

export default {
	s3: {
		REGION: 'eu-west-1',
		BUCKET: 'dn-d-dnext-s3',
	},
	apiGateway: {
		REGION: 'eu-west-1',
		URL: `${serverUrl}storage/request-upload-url`, // not used
		URLClient: `${serverUrl}storage/files/request-upload-url`,
		REST_URL: server + basePath,
	},
	api: {
		split: 3000,
		SERVER: server,
		base: server,
		baseUrl: `${server}${basePath}/`, // Still used for trade_flow, in retrieve_data & deleteRecord functions in List.jsx, and data_sharing in general
		elasticSearchUrl: `${server}index/private`, // Migration tasks created, to be removed after API migration
		catalogUrl: `${server}index`, // Migration tasks created, to be removed after API migration
		searchUrl: `${server}search`, // Migration tasks created, to be removed after API migration
		dataManagement: `${server}${getApiPrefix()}/${dataManagement}/`,
		emailUrl: `${server}${getApiPrefix()}/${storage}/email`,
		dataPrep: `${server}${getApiPrefix()}/${dataPrep}`,
		sharing: `${server}${getApiPrefix()}/sharing/`,
		dataUrl: `${server}${getApiPrefix()}/${data}`,
		taskUrl: `${server}${getApiPrefix()}/tasks`,
		data_sharing: {
			url: `${server}${basePath}/data_sharing`, // To be removed after migrating all data_sharing APIs
		},
	},
	modules: {
		crud: `${server}${basePath}`,
		api: `${server}${dev}`,
	},
	interface: configInterface,
	records: configRecords,
	defaultFields: configDefaultFields,
	systemUser: 'ec044f9e-c52d-4291-99aa-0e7381bfea50',
	labels: {
		system: {
			'dev': 'cyan',
			'test': 'lime',
			'prod': 'green',
			'to review': 'gold',
			'to fix': 'red',
			'low priority': 'blue',
			'high priority': 'volcano',
			'delivered': 'magenta',
		},
		dataset: {
			'to monitor': 'orange',
			'obsolete': 'lightgrey',
			'trade flow': 'purple',
			'D-lineup': 'blue',
			'partial data': 'yellow',
			'final data': 'violet',
			'dashboard': 'default',
			...(isDnexr ? { Graderco: 'geekblue' } : {}),
		},
		trade_flow: {
			'platform only': 'pink',
		},
		data_prep: {
			success: 'green',
			error: 'red',
			ready: 'purple',
			runned: 'blue',
		},
		forecast: {
			tradeflow: 'blue',
			production: 'green',
		},
	},
	badges: {
		None: '#E9E9E9',
		Iron: '#4B4B4B',
		Bronze: '#CD7F32',
		Silver: '#A5A3A3',
		Gold: '#d4af00',
		Platinum: '#a0c5f7',
	},
	form: {
		formLayout: 'vertical',
		fullSpan: 18,
		halfSpan: 9,
		formItemLayout: {
			labelCol: { xs: { span: 12 }, sm: { span: 12 }, md: { span: 12 }, lg: { span: 12 } },
			wrapperCol: {
				xs: { span: 12 },
				sm: { span: 12 },
				md: { span: 12 },
				lg: { span: 12 },
			},
		},
		verticalItemLayout: {
			labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 6 } },
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 24 },
				md: { span: 14 },
			},
		},
		horizontalItemLayout: {
			labelCol: {
				xs: { span: 12 },
				sm: { span: 12 },
				md: { span: 12 },
				lg: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 12 },
				sm: { span: 12 },
				md: { span: 12 },
				lg: { span: 12 },
			},
		},
	},
	restrictedPermission: {
		read: ['voyage', 'estimate'],
		create: ['workflow', 'dashboard', 'voyage', 'forecast', 'member'],
		delete: ['dataset', 'voyage'],
	},
};
