import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// react toast
import { useToasts } from 'react-toast-notifications';
// contexts
import ModalContext from 'src/libs/ModalContext';
import { useUserContext } from 'src/libs/contextLib';
import DataListContext from 'src/libs/DataListContext';
import ReferenceListContext from 'src/libs/ReferenceListContext';
import LabelsListContext from 'src/libs/LabelsListContext';
// General components
import NotFound from 'ui/pages/NotFound';
import NotAccess from 'ui/pages/NotAccess';
import AuthRoute from 'src/utils/AuthRoute';
import RecordList from 'ui/components/List/RecordList';
// configs
import config from 'src/config';
import configUser from 'src/ui/modules/Core/config/user.config';
import configDataset from 'src/ui/modules/Core/config/dataset.config';
import configWorkflow from 'src/ui/modules/Core/config/workflow.config';
import configCategory from 'src/ui/modules/Core/config/category.config';
import configLabel from 'src/ui/modules/Core/config/label.config';
import configFreight from 'src/ui/modules/Freight/config/freight.config';
import configEmail from 'src/ui/modules/Storage/config/email.config';
import configEmailRuleset from 'src/ui/modules/Storage/config/emailRuleset.config';
import configFile from 'src/ui/modules/Storage/config/file.config';
import configDashboard from 'src/ui/modules/Dashboards/config/dashboard.config';
import configAuthentication from 'src/ui/modules/Authentication/config/auth.config';
import configSystemEntity from 'ui/modules/Core/config/system-entity.config';
import configReport from 'ui/modules/Reporting/config/reporting.config';
import configResourceGroup from 'ui/modules/OrganizationSettings/configs/resource-group.config';
import configContract from 'ui/modules/OrganizationSettings/configs/contract.config';
import configScriptLibrary from 'ui/modules/Core/config/script-library.config';
import configDistributionList from 'ui/modules/Reporting/config/distribution-list.config';
import configMember from 'ui/modules/Reporting/config/member.config';

// Authentication
const AuthenticationRoutes = lazy(() => import('ui/modules/Authentication/routes'));
const ChangePassword = lazy(() => import('ui/modules/Authentication/components/ChangePassword/ChangePassword'));

// Home page
const Home = lazy(() => import('ui/pages/Home/Home'));

// Dashboards routing
const DashboardsRoutes = lazy(() => import('ui/modules/Dashboards/dashboard.routes'));

// Datasets
const DatasetRoutes = lazy(() => import('ui/modules/Core/routes/dataset.routes'));
const WorkflowRoutes = lazy(() => import('ui/modules/Core/routes/workflow.routes'));
const PipelineRoutes = lazy(() => import('ui/modules/Pipeline/Routes'));
const Search = lazy(() => import('ui/pages/Search/Search/Search')); // Data catalog

// Fundamentals Modules
const FundamentalsRoutes = lazy(() => import('ui/modules/Fundamentals/Routes'));

// Freight
const FreightRoutes = lazy(() => import('ui/modules/Freight/freight.routes'));

//	Storages
const FilesRoutes = lazy(() => import('ui/modules/Storage/routes/file.routes'));
const EmailRoutes = lazy(() => import('ui/modules/Storage/routes/email.routes'));
const EmailRulesetRoutes = lazy(() => import('ui/modules/Storage/routes/emailRuleset.routes'));

// Reporting
const reportRoutes = lazy(() => import('ui/modules/Reporting/routes/report.routes'));
const DistributionListRoutes = lazy(() => import('ui/modules/Reporting/routes/distribution-list.routes'));
const MemberRoutes = lazy(() => import('ui/modules/Reporting/routes/member.routes'));

// References
const ReferenceData = lazy(() => import('src/ui/pages/ReferenceData/ReferenceData'));
const ReferenceDynamicForm = lazy(() => import('src/ui/modules/References/components/ReferenceDynamicForm'));
const ReferenceDynamicView = lazy(() => import('src/ui/modules/References/components/ReferenceDynamicView'));
const ReferenceDepth = lazy(() => import('src/ui/modules/References/components/ReferenceDepth/ReferenceDepth'));
const OrganizationReferences = lazy(() => import('ui/modules/OrganizationSettings/References'));
const ReferencesCorrection = lazy(
	() => import('ui/modules/OrganizationSettings/References/Correction/ReferencesCorrection')
);
const GlobalValueMapping = lazy(
	() => import('src/ui/modules/References/components/Mapping/GlobalValueMapping/GlobalValueMapping')
);
const DatasetFieldMapping = lazy(
	() => import('src/ui/modules/References/components/Mapping/DatasetFieldMapping/DatasetFieldMapping')
);

// Organization control
const OrganizationSettings = lazy(() => import('ui/modules/OrganizationSettings/Pages/index'));
const contractRoutes = lazy(() => import('ui/modules/OrganizationSettings/routes/contract.routes'));
const resourceGroupRoutes = lazy(() => import('ui/modules/OrganizationSettings/routes/resource-group.routes'));
const UserRoutes = lazy(() => import('ui/modules/Core/routes/user.routes'));

// Environment setting
const CategoryRoutes = lazy(() => import('ui/modules/Core/routes/category.routes'));
const LabelRoutes = lazy(() => import('ui/modules/Core/routes/label.routes'));
const systemEntityRoutes = lazy(() => import('ui/modules/Core/routes/system-entity.routes'));
const scriptLibraryRoutes = lazy(() => import('ui/modules/Core/routes/script-library.routes'));

// My Profile
const Profile = lazy(() => import('ui/modules/Authentication/components/Profile/Profile'));

// Support guide
const Support = lazy(() => import('ui/pages/Support'));

// Api documentation
const API = lazy(() => import('ui/pages/Documentation/API'));

const ModalConsumer = ModalContext.Consumer;
const ReferenceListConsumer = ReferenceListContext.Consumer;
const LabelsListConsumer = LabelsListContext.Consumer;
const DataListConsumer = DataListContext.Consumer;

const FormComponents = {
	company: ReferenceDynamicForm,
	company_group: ReferenceDynamicForm,
	currency: ReferenceDynamicForm,
	custom_code: ReferenceDynamicForm,
	data_type: ReferenceDynamicForm,
	delivery_term: ReferenceDynamicForm,
	exchange: ReferenceDynamicForm,
	exchange_month_reference: ReferenceDynamicForm,
	location: ReferenceDynamicForm,
	location_group: ReferenceDynamicForm,
	period_aggregation_type: ReferenceDynamicForm,
	vegetation_index_variable: ReferenceDynamicForm,
	cost_production_item: ReferenceDynamicForm,
	price_type: ReferenceDynamicForm,
	product: ReferenceDynamicForm,
	product_group: ReferenceDynamicForm,
	quote_type: ReferenceDynamicForm,
	snd_item: ReferenceDynamicForm,
	trade_flow_mode: ReferenceDynamicForm,
	transport_category: ReferenceDynamicForm,
	transport_type: ReferenceDynamicForm,
	unit: ReferenceDynamicForm,
	vessel_status: ReferenceDynamicForm,
	product_category: ReferenceDynamicForm,
	source: ReferenceDynamicForm,
};

const DetailComponents = {
	company: ReferenceDynamicView,
	company_group: ReferenceDynamicView,
	currency: ReferenceDynamicView,
	custom_code: ReferenceDynamicView,
	data_type: ReferenceDynamicView,
	delivery_term: ReferenceDynamicView,
	exchange: ReferenceDynamicView,
	exchange_month_reference: ReferenceDynamicView,
	location: ReferenceDynamicView,
	location_group: ReferenceDynamicView,
	period_aggregation_type: ReferenceDynamicView,
	cost_production_item: ReferenceDynamicView,
	price_type: ReferenceDynamicView,
	product_category: ReferenceDynamicView,
	product: ReferenceDynamicView,
	product_group: ReferenceDynamicView,
	quote_type: ReferenceDynamicView,
	snd_item: ReferenceDynamicView,
	source: ReferenceDynamicView,
	trade_flow_mode: ReferenceDynamicView,
	transport_category: ReferenceDynamicView,
	transport_type: ReferenceDynamicView,
	unit: ReferenceDynamicView,
	vegetation_index_variable: ReferenceDynamicView,
	vessel_status: ReferenceDynamicView,
};

const ReferenceDepthComponents = {
	company: ReferenceDepth,
	company_group: ReferenceDepth,
	custom_code: ReferenceDepth,
	location: ReferenceDepth,
	location_group: ReferenceDepth,
	cost_production_item: ReferenceDepth,
	product: ReferenceDepth,
	product_group: ReferenceDepth,
	source: ReferenceDepth,
	transport_type: ReferenceDepth,
};
// Comment to be deleted
export default function Routes(props: any) {
	const { addToast } = useToasts();
	const { myPermissions, user } = useUserContext() as any;
	return (
		<ModalConsumer>
			{(modalConsumerProps) => (
				<ReferenceListConsumer>
					{(referenceListConsumerProps) => (
						<LabelsListConsumer>
							{(labelsListConsumerProps) => (
								<DataListConsumer>
									{(dataListConsumerProps) => (
										<Switch>
											{/* Authentication */}
											<AuthRoute
												exact={false}
												authRoute={false}
												path={configAuthentication.path}
												component={AuthenticationRoutes}
												{...modalConsumerProps}
											/>
											{/* Home page */}
											<Route exact path="/">
												<Redirect to="/home" />
											</Route>
											<AuthRoute exact path="/home" authRoute pageTitle="Home">
												<Home
													addToast={addToast}
													{...modalConsumerProps}
													{...referenceListConsumerProps}
												/>
											</AuthRoute>
											{/* Dashboards */}
											<AuthRoute
												exact={false}
												authRoute
												path={configDashboard.basePath}
												pageTitle={configDashboard.pageTitle.list}
												component={DashboardsRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="dashboard"
											/>
											{/* datasets */}
											<AuthRoute
												exact={false}
												authRoute
												path={configDataset.path}
												pageTitle={configDataset.pageTitle.list}
												component={DatasetRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												labelsListConsumerProps={labelsListConsumerProps}
												permissionRecord="dataset"
											/>
											{/* workflows */}
											<AuthRoute
												exact={false}
												authRoute
												path={configWorkflow.path}
												pageTitle={configWorkflow.pageTitle.list}
												component={WorkflowRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="workflow"
											/>
											{/* Users */}
											<AuthRoute
												exact={false}
												authRoute
												path={configUser.path}
												pageTitle={configUser.pageTitle.list}
												component={UserRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="user"
											/>
											{/* Categories */}
											<AuthRoute
												exact={false}
												authRoute
												path={configCategory.path}
												pageTitle={configCategory.pageTitle.list}
												component={CategoryRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="category"
											/>
											{/* Labels */}
											<AuthRoute
												exact={false}
												authRoute
												path={configLabel.path}
												pageTitle={configLabel.pageTitle.list}
												component={LabelRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="label"
											/>
											{/* System Entities */}
											<AuthRoute
												exact={false}
												authRoute
												path={configSystemEntity.path}
												pageTitle={configSystemEntity.pageTitle.list}
												component={systemEntityRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="system_entity"
											/>
											{/* Script library */}
											<AuthRoute
												exact={false}
												authRoute
												path={configScriptLibrary.path}
												pageTitle={configScriptLibrary.pageTitle.list}
												component={scriptLibraryRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												labelsListConsumerProps={labelsListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="script_library"
											/>
											{/* Distribution List */}
											<AuthRoute
												exact={false}
												authRoute
												path={configDistributionList.path}
												pageTitle={configDistributionList.pageTitle.list}
												component={DistributionListRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												labelsListConsumerProps={labelsListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="report"
											/>
											{/* Member */}
											<AuthRoute
												exact={false}
												authRoute
												path={configMember.path}
												pageTitle={configMember.pageTitle.list}
												component={MemberRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												labelsListConsumerProps={labelsListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="report"
											/>
											{/* Reports */}
											<AuthRoute
												exact={false}
												authRoute
												path={configReport.path}
												pageTitle={configReport.pageTitle.list}
												component={reportRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="report"
											/>
											{/* Emails */}
											<AuthRoute
												exact={false}
												authRoute
												path={configEmail.path}
												pageTitle={configEmail.pageTitle.list}
												component={EmailRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="email"
											/>
											{/* Email ruleset */}
											<AuthRoute
												exact={false}
												authRoute
												path={configEmailRuleset.path}
												pageTitle={configEmailRuleset.pageTitle.list}
												component={EmailRulesetRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="email_ruleset"
											/>

											{/* Files */}
											<AuthRoute
												exact={false}
												authRoute
												path={configFile.path}
												pageTitle={configFile.page_title}
												component={FilesRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="file"
											/>
											<AuthRoute path="/fundamentals" authRoute>
												<FundamentalsRoutes
													{...props}
													addToast={addToast}
													{...modalConsumerProps}
													{...referenceListConsumerProps}
													{...labelsListConsumerProps}
													dataListConsumerProps={dataListConsumerProps}
													user
												/>
											</AuthRoute>
											<AuthRoute path="/data-prep" authRoute>
												<PipelineRoutes
													{...props}
													addToast={addToast}
													{...modalConsumerProps}
													{...referenceListConsumerProps}
													dataListConsumerProps={dataListConsumerProps}
													user
												/>
											</AuthRoute>
											{/* Organization Control */}
											<AuthRoute path="/organization" authRoute>
												<OrganizationSettings
													{...props}
													addToast={addToast}
													{...modalConsumerProps}
													{...referenceListConsumerProps}
													dataListConsumerProps={dataListConsumerProps}
													user={user}
												/>
											</AuthRoute>
											<AuthRoute
												exact={false}
												authRoute
												path={configResourceGroup.path}
												component={resourceGroupRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="resource_group"
											/>
											<AuthRoute
												exact={false}
												authRoute
												path={configContract.path}
												component={contractRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												dataListConsumerProps={dataListConsumerProps}
												referenceListConsumerProps={referenceListConsumerProps}
												permissionRecord="contract"
											/>
											<AuthRoute
												exact={false}
												authRoute
												path={configFreight.path}
												pageTitle={configFreight.description}
												component={FreightRoutes}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												referenceListConsumerProps={referenceListConsumerProps}
											/>
											<AuthRoute
												exact={false}
												path="/support"
												authRoute
												pageTitle="Support"
												component={Support}
												user={user}
												{...props}
												{...modalConsumerProps}
												addToast={addToast}
												referenceListConsumerProps={referenceListConsumerProps}
												dataListConsumerProps={dataListConsumerProps}
											/>
											<AuthRoute
												exact
												path="/reference-data"
												authRoute
												pageTitle={config.records.reference_data.pageTitle.list}
												render={(props: any) => {
													if (!myPermissions?.reference?.can_read) {
														return <NotAccess />;
													}
													return (
														<ReferenceData
															addToast={addToast}
															{...props}
															{...modalConsumerProps}
															permissionRecord="reference"
														/>
													);
												}}
											/>
											<AuthRoute
												{...props}
												path="/references-management/correction/:tab?"
												authRoute
											>
												{!myPermissions?.reference?.can_read ? (
													<NotAccess />
												) : (
													<ReferencesCorrection
														{...props}
														addToast={addToast}
														{...modalConsumerProps}
														{...referenceListConsumerProps}
														dataListConsumerProps={dataListConsumerProps}
														user={user}
													/>
												)}
											</AuthRoute>
											<AuthRoute {...props} exact path="/references-management" authRoute>
												{!myPermissions?.reference?.can_read ? (
													<NotAccess />
												) : (
													<OrganizationReferences
														{...props}
														addToast={addToast}
														{...modalConsumerProps}
														{...referenceListConsumerProps}
														dataListConsumerProps={dataListConsumerProps}
														user={user}
													/>
												)}
											</AuthRoute>
											<AuthRoute
												exact
												path="/global-reference-mapping"
												authRoute
												pageTitle="Global value mapping"
											>
												{!myPermissions?.mapping?.can_read ? (
													<NotAccess />
												) : (
													<GlobalValueMapping
														{...modalConsumerProps}
														{...referenceListConsumerProps}
														addToast={addToast}
													/>
												)}
											</AuthRoute>
											<AuthRoute
												exact
												path="/dataset-reference-mapping"
												authRoute
												pageTitle="Dataset Reference Mapping"
											>
												{!myPermissions?.mapping?.can_read ? (
													<NotAccess />
												) : (
													<DatasetFieldMapping
														{...modalConsumerProps}
														{...referenceListConsumerProps}
														addToast={addToast}
													/>
												)}
											</AuthRoute>
											<AuthRoute
												{...modalConsumerProps}
												{...referenceListConsumerProps}
												path="/search/:code?"
												authRoute
												pageTitle="Search"
											>
												<Search {...props} addToast={addToast} />
											</AuthRoute>
											{/* Profile & change password */}
											<AuthRoute
												exact
												path="/password-change"
												authRoute
												pageTitle="Password change"
											>
												<ChangePassword {...modalConsumerProps} />
											</AuthRoute>
											<AuthRoute
												exact
												path="/profile/:tab?"
												pageTitle="User profile"
												authRoute
												render={(props: any) => {
													if (!myPermissions?.profile?.can_read) {
														return <NotAccess />;
													}
													return (
														<Profile
															{...modalConsumerProps}
															{...props}
															user={user}
															addToast={addToast}
														/>
													);
												}}
											/>
											<AuthRoute
												exact
												path="/documentation/api/v1"
												pageTitle="API documentation"
												authRoute
												render={(props: any) => (
													<API
														{...modalConsumerProps}
														{...props}
														user={user}
														addToast={addToast}
														version="v1.0"
													/>
												)}
											/>

											{/* Record lists and record forms */}
											{Object.entries(config.records).map(([recordType, configRecord]) => {
												if (Array.isArray((configRecord as any).columns)) {
													if ((configRecord as any).filteredByType)
														configRecord.path = `/:type${configRecord.path.replace('/:type', '')}`;
													let authRouteTree = [
														<AuthRoute
															key={`${recordType}Depth`}
															pageTitle={(configRecord.pageTitle as any).action.depth}
															path={`${configRecord.path}/tree`}
															authRoute
															{...props}
															{...modalConsumerProps}
															{...referenceListConsumerProps}
															component={
																ReferenceDepthComponents[
																	recordType as keyof typeof ReferenceDepthComponents
																]
															}
															recordType={recordType}
															addToast={addToast}
															permissionRecord={
																(configRecord as any).referenceRecord
																	? 'reference'
																	: recordType
															}
														/>,
														<AuthRoute
															key={`${recordType}Depth`}
															pageTitle={(configRecord.pageTitle as any).action.depth}
															path={`${configRecord.path}/:code/tree`}
															authRoute
															{...props}
															{...modalConsumerProps}
															{...referenceListConsumerProps}
															component={
																ReferenceDepthComponents[
																	recordType as keyof typeof ReferenceDepthComponents
																]
															}
															recordType={recordType}
															addToast={addToast}
															permissionRecord={
																(configRecord as any).referenceRecord
																	? 'reference'
																	: recordType
															}
														/>,
													];

													return [
														<AuthRoute
															pageTitle={configRecord.pageTitle.list}
															exact
															path={configRecord.path}
															authRoute
															addToast={addToast}
															component={RecordList}
															key={`${recordType}List`}
															configList={configRecord}
															recordType={recordType}
															user={user}
															navigateByCode={
																(configRecord as any).navigateByCode || false
															}
															{...props}
															{...modalConsumerProps}
															onReceiveNotification={
																(configRecord as any).onReceiveNotification
															}
															{...referenceListConsumerProps}
															{...labelsListConsumerProps}
															permissionRecord={
																(configRecord as any).referenceRecord
																	? 'reference'
																	: recordType
															}
														/>,
														<AuthRoute
															key={`${recordType}Form`}
															pageTitle={(configRecord.pageTitle as any).action.edit}
															path={`${configRecord.path}/:id/${config.interface.editRecordUrlValue}/:tab?`}
															authRoute
															{...props}
															{...modalConsumerProps}
															{...referenceListConsumerProps}
															component={
																FormComponents[
																	recordType as keyof typeof FormComponents
																]
															}
															recordType={recordType}
															addToast={addToast}
															permissionRecord={
																(configRecord as any).referenceRecord
																	? 'reference'
																	: recordType
															}
														/>,

														<AuthRoute
															key={`${recordType}FormNew`}
															pageTitle={(configRecord.pageTitle as any).action.create}
															path={`${configRecord.path}/${config.interface.newRecordUrlValue}`}
															authRoute
															{...props}
															{...modalConsumerProps}
															{...referenceListConsumerProps}
															component={
																FormComponents[
																	recordType as keyof typeof FormComponents
																]
															}
															recordType={recordType}
															addToast={addToast}
															permissionRecord={
																(configRecord as any).referenceRecord
																	? 'reference'
																	: recordType
															}
														/>,
														(configRecord as any)?.parentField && authRouteTree,
														DetailComponents[
															recordType as keyof typeof DetailComponents
														] ? (
															<AuthRoute
																exact
																key={recordType}
																pageTitle={configRecord.pageTitle.list}
																path={`${configRecord.path}/:id/:tab?`}
																authRoute
																{...modalConsumerProps}
																{...referenceListConsumerProps}
																component={
																	DetailComponents[
																		recordType as keyof typeof DetailComponents
																	]
																}
																recordType={recordType}
																addToast={addToast}
																permissionRecord={
																	(configRecord as any).referenceRecord
																		? 'reference'
																		: recordType
																}
															/>
														) : null,
													];
												}

												return [];
											})}
											<Route>
												<NotFound />
											</Route>
										</Switch>
									)}
								</DataListConsumer>
							)}
						</LabelsListConsumer>
					)}
				</ReferenceListConsumer>
			)}
		</ModalConsumer>
	);
}
