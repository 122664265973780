// utils
import { dataPrep, getApiPrefix, server } from 'src/utils/api-prefix.helper';
import { pushNotification } from 'src/utils/PushNotification';
//services
import { getPipelineService, downloadFile } from 'ui/modules/Pipeline/utils/service.dataprep';
// interface
import { IWebSocketResponse } from 'ui/modules/Core/types/interfaces/socket.interface';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;
const dataPrepUrl = `${serverUrl}${dataPrep}`;
export default {
	referenceData: false,
	iconName: 'DataTransform',
	url: `${dataPrepUrl}/`,
	getService: () => ({
		instance: getPipelineService,
	}),
	recordid: 'id', // required for duplication !
	urlParams: '',
	path: '/data-prep',
	parentPage: {
		name: 'Data Prep',
	},
	pageTitle: {
		list: 'Data Prep List',
		action: {
			create: 'New Data Prep',
			edit: 'Edit Data Prep',
		},
	},

	onReceiveNotification(event: IWebSocketResponse) {
		if (event.code === 'preparing_data')
			this._alterRowData(event.object_id, {
				last_status: 'running',
			});
		const details = event.details;
		if (
			typeof details === 'object' &&
			event.code === 'dataprep_success' &&
			details?.direct &&
			details?.task_id &&
			details?.url &&
			// to avoid multiple download from different visible window instances
			document.visibilityState === 'visible'
		) {
			downloadFile(details?.url, details?.task_id);
		}

		if (event.type === 'error' || event.type === 'success') {
			const newStatus = event.type;

			this._alterRowData(event.object_id, { last_status: newStatus }, null, 'code');
		}

		pushNotification(
			this.props.addToast,
			this.props.t(`modules.pipeline.messages.${event.type}.${event.code}`),
			event.type
		);
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
			type: 'text',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Code',
			field: 'code',
			type: 'text',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Tag',
			field: 'tag',
			type: 'label',
			minWidth: 220,
			width: 200,
			typeParams: {
				null_value: '_',
			},
		},
		{
			headerName: 'Status',
			field: 'last_status',
			type: 'label',
			minWidth: 200,
			typeParams: {
				null_value: 'Not yet run',
			},
		},
		{
			headerName: 'Last run at',
			field: 'last_run_at',
			type: 'datetime',
			minWidth: 200,
			headerTooltip: 'Last execution date of the workflow',
		},
		{
			headerName: 'Updated At',
			field: 'updated_at',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'Created At',
			field: 'created_at',
			type: 'datetime',
			minWidth: 200,
		},
	],
	canView: true,
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canBulkExecute: false,
	useTab: 'general',
	canExecuteDataPrep: true,
	navigateByCode: true,
};
