export default {
	name: 'Member',
	name_plural: 'Members',
	new_record_title: 'Members List',
	tabs: {
		summary: 'Summary',
		usages: 'Usages',
	},
	actions: {
		create_new: 'New Member',
		view_member: 'View Member',
	},
	success: {
		update_member: 'Member updated successfully',
		delete_member: 'Member(s) deleted successfully',
	},
	errors: {
		retrieve_all_members: 'An error occurred while retrieving all members data',
		retrieve_member: 'An error occurred while retrieving member data',
		create_member: 'An error occurred while creating a new member',
		update_member: 'An error occurred while updating this member',
		delete_member: 'An error occurred while deleting member(s)',
	},
	required: {
		email: 'Email Field is required for member creation',
		first_name: 'First Name Field is required for member creation',
		last_name: 'Last Name Field is required for member creation',
	},
	actions: {
		add_member: 'Add Member',
		add_email: 'Add Email',
	},
	placeholders: {
		write_email: 'example@dnext.io',
		select_email: 'Select Email',
	},
	messages: {
		email_exists: 'Email already added to current members list',
		email_format: 'Standard Email Format is Required.',
	},
	labels: {
		usage_member: 'Belongs To The Following Distribution Lists',
	},
	descriptions: {
		usage_member:
			'This member will be receiving emails related to reports having these distribution lists in email delivery configuration.',
	},
	validation: {
		first_name: 'First Name Field is Required',
		last_name: 'Last Name Field is Required',
		email: 'Email Field is Required',
	},
};
