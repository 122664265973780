// Dependencies
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Ant design & Fontawesome
import { Button } from 'antd';
// Configs
import config from 'config';
// Helpers
import { useUserContext } from 'src/libs/contextLib';
interface NavigationButtonProps {
	recordType: string;
}
const NavigationButton: FC<NavigationButtonProps> = ({ recordType = 'forecast' }) => {
	const { myPermissions } = useUserContext();
	const { t: translate } = useTranslation();
	const configRecord = config.records[recordType];
	return (
		<Button
			onClick={() => {
				window.open(configRecord.path);
			}}
			key={recordType}
			disabled={!myPermissions[recordType === 'member' ? 'report' : recordType]?.can_read}
		>
			{translate(`model.${recordType}.name_plural`)}
		</Button>
	);
};
export default NavigationButton;
