//services
import MemberService from 'ui/modules/Reporting/services/member.service';
import { MemberColumns } from 'ui/modules/Reporting/constants/member.constant';

export default {
	referenceData: false,
	iconName: 'Member',
	path: '/members',
	pageTitle: {
		list: 'Members',
		action: {
			create: 'New Member',
			edit: 'Edit Member',
		},
	},
	columns: MemberColumns,
	getService: () => ({
		instance: MemberService.getInstance,
		bulkDelete: MemberService.bulkDelete,
	}),
};
