// service
import DataService from 'src/utils/DataService';
// config
import config from 'src/config';
//helpers
import { getSplitResult } from 'ui/modules/Core/utils/shared.helper';
import { dataManagement, getApiPrefix, server, referenceDataUrl } from 'src/utils/api-prefix.helper';
// constants
const { elasticSearchUrl } = config.api;
const serverUrl = `${server}${getApiPrefix()}/`;

//launch api reference n times based on total_size
export function getReferenceDataByType(currConfig: any, approved = false, active = false) {
	const recordType = currConfig?.groupOf ? currConfig.groupOf : currConfig?.referenceRecord;
	let params = currConfig?.groupOf ? '&params=is_group' : currConfig?.is_mixed ? '&params=is_mixed' : '';
	params += currConfig?.is_not_linked ? '&is_not_linked=true' : '';
	params += approved ? '&approved=true' : ''; // This passed true to get only approved references, otherwise you get approved and pending refs
	params += active ? '&is_active=true' : ''; // This passed true to get only active references, otherwise you get active and inactive refs
	const baseUrl = `${referenceDataUrl}${recordType}`;
	const split = config.api.split || 3000;
	return new DataService({
		url: `${baseUrl}?limit=${split}&offset=${0}${params}`,
		urlParams: '',
		token: true,
	})
		.getAll()
		.then(({ data }) => {
			return getSplitResult(data, recordType, params);
		});
}

export function getOneReferenceByType(recordType: string, id: string, params: string = '') {
	return new DataService({
		url: `${referenceDataUrl}${recordType}`,
		urlParams: params,
	}).getClean(id);
}

export const createReference = async (recordType: string, payload: any) => {
	return await new DataService({
		url: `${referenceDataUrl}${recordType}`,
		urlParams: '',
		token: true,
	}).create(payload);
};

export const updateReference = async (recordType: string, code: string, payload: any) => {
	return await new DataService({
		url: `${referenceDataUrl}${recordType}`,
		urlParams: '',
		token: true,
	}).update(code, payload);
};

export const deleteReferenceByCode = async (recordType: string, code: string) => {
	return await new DataService({
		url: `${referenceDataUrl}${recordType}`,
		urlParams: '',
		token: true,
	}).delete(code);
};

export function bulkDeleteReferences(recordType: string, payload: { codes: string[] }) {
	return new DataService({
		url: `${referenceDataUrl}${recordType}/delete`,
		urlParams: '',
	}).post(payload);
}

export const triggerReferenceSyncService = async () => {
	return await new DataService({
		url: `${serverUrl}${dataManagement}/trigger-reference-sync`,
		urlParams: '',
		token: true,
	}).getClean();
};
