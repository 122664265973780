/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, { Component, lazy } from 'react';
import { withTranslation } from 'react-i18next';
import { isArray } from 'lodash';
// ant design & icons
import { TreeSelect, Spin, Input, Typography, Button, Divider, Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faFolder, faSearch, faSyncAlt } from '@fortawesome/pro-light-svg-icons';
// components
import userContext from 'src/libs/contextLib';
// config
import config from 'config';
// services
import DataService from 'src/utils/DataService';
import {
	contextOrAPIGetReference,
	sortReferenceOptions,
	normalizedValue,
} from 'ui/modules/References/utils/reference.helper';
// sass & css
import './ReferenceSelect.sass';

const ReferenceDynamicForm = lazy(() => import('src/ui/modules/References/components/ReferenceDynamicForm'));
const CategoryForm = lazy(() => import('ui/modules/Core/components/Categories/CategoryForm/CategoryForm'));

const formComponents = {
	company: ReferenceDynamicForm,
	company_group: ReferenceDynamicForm,
	currency: ReferenceDynamicForm,
	custom_code: ReferenceDynamicForm,
	data_type: ReferenceDynamicForm,
	delivery_term: ReferenceDynamicForm,
	exchange: ReferenceDynamicForm,
	exchange_month_reference: ReferenceDynamicForm,
	location: ReferenceDynamicForm,
	location_group: ReferenceDynamicForm,
	period_aggregation_type: ReferenceDynamicForm,
	vegetation_index_variable: ReferenceDynamicForm,
	cost_production_item: ReferenceDynamicForm,
	price_type: ReferenceDynamicForm,
	product: ReferenceDynamicForm,
	product_group: ReferenceDynamicForm,
	quote_type: ReferenceDynamicForm,
	snd_item: ReferenceDynamicForm,
	trade_flow_mode: ReferenceDynamicForm,
	transport_category: ReferenceDynamicForm,
	transport_type: ReferenceDynamicForm,
	unit: ReferenceDynamicForm,
	vessel_status: ReferenceDynamicForm,
	product_category: ReferenceDynamicForm,
	source: ReferenceDynamicForm,
	category: CategoryForm,
};
const { Text } = Typography;

const sortFunction = (x, y) => {
	if (x.isGroup === y.isGroup) {
		if (x.parents === y.parents) {
			return x.text.localeCompare(y.text);
		}
		return x.parents.localeCompare(y.parents);
	}
	if (x.isGroup) {
		return 1;
	}
	return -1;
};
const sortOptions = (options) => options.sort(sortFunction);

const getRecordId = (value, currConfig, data) => {
	if (value) {
		const id = value.includes('#') ? value.split('#')[1] : value;
		const { navigateByCode } = currConfig;
		if (navigateByCode) {
			const code = (data || []).find((el) => el.id === id)?.code;
			if (code) return code;
			return null;
		}
		return id;
	}
	return null;
};

/**
 * ReferenceSelect component.
 *
 * @component
 * @extends Component
 *
 * @param {Object} props - Component properties.
 *
 * @param {string} props.recordtype - The type of record to be referenced.
 * @param {boolean} [props.hideOpenReference] - Whether to hide the open reference button.
 *
 * @param {function} [props.optionlabelfunction] - Function to generate option labels.
 *
 * @param {boolean} [props.treeCheckable] - Whether the tree is checkable.
 * @param {boolean} [props.treeCheckStrictlyStrategry] - Whether to use strict tree check strategy.
 *
 * @param {string} [props.filtertype] - Field to filter on for elements.
 * @param {string} [props.filtervalue] - Value of the filter to apply.
 *
 * @param {Array} [props.suggestedValues] - Suggested values for the reference.
 * @param {boolean} [props.confidencescore] - Confidence score for the suggested value.
 * @param {Array} [props.disabledItems] - Disabled items for the reference.
 *
 * @param {boolean} [props.disabled] - Whether the reference is disabled.
 * @param {boolean} [props.desirecode] - Whether to use desire code.
 * @param {boolean} [props.onlycode] - Whether to use only code.
 * @param {boolean} [props.dataSetCreation] - Whether this is part of dataset creation
 * @param {boolean} [props.addnone] - Whether to add a "None" option.
 * @param {boolean} [props.popUpCreation] - Whether to show popup creation.
 * @param {boolean} [props.allowClear] - Whether to allow clearing the selection.
 *
 * @param {Object} [props.style] - Custom styles for the component.
 * @param {string} [props.className] - Custom class name for the component.
 * @param {string} [props.size] - Size of the component.
 * @param {ReactNode} [props.suffixIcon] - Suffix icon for the component.
 *
 * @param {function} [props.pushNotification] - Function to push notifications.
 * @param {boolean} [props.isReferenceListLoaded] - Whether the reference list is loaded.
 * @param {function} [props.setReferenceListValues] - Function to set reference list values.
 * @param {function} [props.getReferenceListValues] - Function to get reference list values.
 *
 * @param {string} [props.value] - Current value of the reference select.
 * @param {string} [props.defaultValue] - Default value of the reference select.
 *
 * @param {function} [props.t] - Translation function provided by withTranslation.
 * @param {boolean} [props.isLoadingPreloadedData] - Whether preloaded data is being loaded.
 * @param {boolean} [props.isEditableCell] - Whether the component is in an editable cell.
 * @param {function} [props.onSelectEditableCell] - Function to handle selection in an editable cell.
 * @param {function} [props.onChange] - Function to handle change in the reference select.
 *
 * @param {Object} [props.form] - Form instance for setting field values.
 * @param {function} [props.changefly] - Function to handle change on the fly.
 */
class ReferenceSelect extends Component {
	// eslint-disable-next-line
	static contextType = userContext;

	constructor(props) {
		super(props);

		this.state = {
			options: [],
			ready: false,
			fetching: false,
			drawerVisible: false,
			searchTerm: '',
		};

		this.recordType = props.recordtype.toLowerCase() === 'data_source' ? 'source' : props.recordtype.toLowerCase();

		this.access = true;
		this.parentField = config.records[this.recordType]?.parentField;
		this.hideOpenReference = props.hideOpenReference;
		this.confidencescore = props.confidencescore;
		this.apiURL = config.records[this.recordType]?.url;
		this.defaultOrder = '&order=[["name", "ASC"]]';

		this.optionLabelFunction =
			props.optionlabelfunction ||
			function optionlabelfunction(data) {
				let label = data.name;
				if (data.code) label += ` (${data.code})`;
				return label;
			};
		this.treeWidth = this.hideOpenReference || this.props.treeCheckable ? '100%' : 'calc(100% - 42px)';
		this.filterType = this.props.filtertype !== undefined ? this.props.filtertype : null;
		this.filterValue = this.props.filtervalue;

		this.data = null;
		this.dataReady = null;
		this.buildAllOptions = false;
	}

	componentDidMount() {
		const { context } = this;
		this.myPermissions = context.myPermissions || null;
		if (this.recordType && !this.myPermissions?.reference?.can_create) {
			this.access = false;
		}

		this.dataReady = this.fetch();
	}

	componentDidUpdate(prevProps) {
		if (this.props.value && prevProps.value !== this.props.value) {
			this.dataReady.then(() => {
				this.buildOptions(true);
			});
		}
	}

	// eslint-disable-next-line class-methods-use-this
	handleKeyDown(event) {
		if (event?.nativeEvent.stopImmediatePropagation && event.ctrlKey && (event.key === 'c' || event.key === 'v')) {
			event.nativeEvent.stopImmediatePropagation();
		}
	}

	onPopUpCreate(item, keepVisible = false) {
		const newValue = {};
		const desirecode = this.props.desirecode || false;

		// inject new value into the options
		this.data.push(item);

		// rebuild all options
		this.buildOptions();

		this.setState({
			drawerVisible: keepVisible,
		});

		newValue[this.props.id] = item.value;

		if (desirecode) {
			if (this.props.form)
				this.props.form.current.setFieldsValue({ [this.props.id]: `${item.code}#${item.value}` });
		} else {
			if (this.props.changefly) {
				this.props.changefly(newValue);
			}
			this.props.form.current.setFieldsValue(newValue);
		}
	}

	onLoadData() {
		this.buildOptions();
	}

	buildOptions = (onlyCurrentValue) => {
		let data = this.data;

		const { parentField, recordType, filterType, filterValue, buildAllOptions } = this;

		const desirecode = this.props.desirecode || false;
		const onlycode = this.props.onlycode || false;
		const dataSetCreation = this.props.dataSetCreation || false;
		const confidencescore = this.props.confidencescore;
		const treeCheckable = this.props.treeCheckable || false;
		const suggestedValuesSet = new Set(this.props.suggestedValues || []);
		const disableItemsSet = new Set(this.props.disabledItems || []);

		// build map of elements to build hierarchy
		const itemsMap = {};

		// build data value upfront
		data.forEach((element) => {
			let datavalue = desirecode
				? `${element.code}#${element.id}${dataSetCreation ? `#${element.name}` : ''}`
				: element.id;
			if (onlycode) {
				datavalue = element.code;
			}
			element.datavalue = datavalue;
		});

		// special case for custom codes
		if (recordType === 'custom_code_not_linked' || recordType === 'custom_code') {
			data.forEach((element) => {
				itemsMap[element.code] = element;
			});
		} else {
			data.forEach((element) => {
				itemsMap[element.id] = element;
			});
		}

		// helper to get parent
		const getParent =
			this.recordType === 'custom_code_not_linked' || this.recordType === 'custom_code'
				? (element) => itemsMap[element.code?.slice(0, -2)]
				: (element) => itemsMap[element[parentField]] || element.direct_parent;

		if (onlyCurrentValue && !buildAllOptions) {
			const value = this.props.value || this.props.defaultValue;
			if (treeCheckable && isArray(value)) {
				data = data.filter((el) => value.includes(el.datavalue));
			} else {
				data = data.filter((el) => el.datavalue === value);
			}
		} else {
			this.buildAllOptions = true;
			if (filterType !== null && filterValue !== null) {
				data = data.filter((el) => el[filterType] === filterValue);
			}
		}

		// build options
		const options = data.map((element) => {
			let labelText;
			if (element.label && typeof element.label === 'string') {
				labelText = element.label;
			} else if (element.text && typeof element.text === 'string') {
				labelText = element.text;
			} else {
				labelText = this.optionLabelFunction(element);
			}

			// build hierarchy
			let nbLevel = 0;
			let parentElement = getParent(element);
			const path = [{ name: '' }];
			const parentIds = new Set([parentElement?.id]);
			while (parentElement && nbLevel < 10) {
				path.unshift(parentElement);
				nbLevel += 1;
				parentElement = getParent(parentElement);
				const parentId = parentElement?.id;
				if (parentId && parentIds.has(parentId)) {
					break;
				}
				parentIds.add(parentId);
			}

			const parents = path.map((el) => el.name).join(' / ');
			const datavalue = element.datavalue;

			const item = {
				label: labelText,
				value: datavalue,
				key: datavalue,
				text: labelText,
				code: element.code,
				name: element.name,
				id: element.id,
				isGroup: element.is_group,
				parents,
			};

			if (disableItemsSet.has(element.id)) {
				item.disabled = true;
			}

			return item;
		});

		const hasParent = options.some((el) => el.parents);

		options.forEach((item) => {
			let label = item.label;

			// if parents needs to be displayed, wrap the label
			if (hasParent) {
				const parents = item.parents;
				label = (
					<span className="content-item">
						{parents !== '' ? <span className="parent_element">{parents}</span> : null}
						{label}
					</span>
				);
			}

			// add group icon if is_group
			if (item.isGroup) {
				label = (
					<>
						<span style={{ marginRight: '5px' }} className="group-icon">
							<FontAwesomeIcon icon={faFolder} />
						</span>
						{label}
					</>
				);
			}

			// add suggestion tag
			if (suggestedValuesSet.has(item.value)) {
				label = (
					<div className="suggested-value">
						<div className="suggested-label">{label}</div>

						<div className="suggested-indicator">
							<Text type={confidencescore === 1 ? 'success' : 'warning'}>Suggested</Text>
						</div>
					</div>
				);
			}

			item.label = label;

			return item;
		});

		if (this.props.addnone) {
			options.unshift({
				label: 'None',
				value: 'none',
				key: 'none',
				text: 'None',
				id: 'none',
				pId: null,
				disabled: false,
			});
		}

		sortOptions(options);

		this.setState({ options });
	};

	goToReference() {
		const currConfig = config.records?.[this.recordType];

		if (!currConfig) return;

		const recordField = getRecordId(this.props.value, currConfig, this.data);

		const matchingObject = (this.data || []).find((el) => el.code === recordField);

		if (matchingObject) {
			const { path, groupOf } = currConfig;
			const referenceURL = `${path}${!groupOf && matchingObject?.is_group ? '-group' : ''}/${recordField}`;
			window.open(referenceURL, '_blank');
		}
	}

	// eslint-disable-next-line class-methods-use-this
	showScrollBarOnTop() {
		const element = document.querySelectorAll('.ant-select-tree-list-holder');
		if (element) {
			Array.from(element).forEach((el) => {
				el.scrollTop = 0;
			});
		}
	}

	async fetch(refresh) {
		let data = this.props.data;

		if (refresh || !data?.length) {
			const currConfig = config.records[this.recordType];
			this.setState({ fetching: true });

			const target = { url: this.apiURL, search: false };

			// References case record type is a reference
			if (currConfig.referenceRecord) {
				data = await contextOrAPIGetReference(this.recordType, this.props, !refresh, true);
			} else {
				const service = currConfig.getService
					? currConfig.getService().instance()
					: new DataService({
							url: target.url,
							urlParams: `?exclude=[parent]&limit=10000${this.defaultOrder}`,
						});
				let result = { data: [] };
				if (!this.props?.isReferenceListLoaded(this.recordType) || refresh) {
					result = await service.getAll();
					this.props.setReferenceListValues(this.recordType, result);
				} else {
					result.data = this.props.getReferenceListValues(this.recordType)?.[0];
				}
				data = Array.isArray(result?.data?.result) ? result?.data?.result : result?.data;
			}
		}

		this.data = data;

		if (this.props.value || this.props.defaultValue) {
			// prepare data for the current value
			this.buildOptions(true);
		}
		this.setState({ ready: true, fetching: false });
	}

	render() {
		const currConfigRecord = this.recordType?.replace(/_and_group|_not_linked/g, '');

		const { ready, fetching, searchTerm, options } = this.state;
		const {
			allowClear,
			className,
			defaultValue,
			disabled,
			getReferenceListValues,
			hideOpenReference,
			isLoadingPreloadedData,
			isReferenceListLoaded,
			isSuggestedValue,
			onChange,
			popUpCreation,
			pushNotification,
			recordtype,
			setReferenceListValues,
			size,
			style,
			suffixIcon,
			treeCheckable,
			treeCheckStrictlyStrategry,
			value,
		} = this.props;

		if (!ready || isLoadingPreloadedData) {
			return <Spin size="small" />;
		}

		const showDrawer = popUpCreation === undefined ? true : popUpCreation;
		const showOpenReference = !hideOpenReference && !treeCheckable;

		const TreeComponent = (
			<TreeSelect
				disabled={disabled}
				size={size}
				value={value}
				suffixIcon={suffixIcon}
				defaultValue={defaultValue}
				treeCheckStrictly={
					treeCheckStrictlyStrategry ? treeCheckStrictlyStrategry && treeCheckable : true && treeCheckable
				}
				treeCheckable={treeCheckable}
				treeNodeLabelProp="label"
				treeDefaultExpandAll
				showSearch
				className={
					(isSuggestedValue ? ' suggested-value' : '') +
					(isSuggestedValue && this.confidencescore === 1 ? ' confident-suggestion' : '') +
					(className || '')
				}
				style={{ width: this.treeWidth, ...style }}
				allowClear={allowClear}
				dropdownMatchSelectWidth
				autoComplete="dontshow"
				placeholder={`Select a ${recordtype.replace(/_/g, ' ')}`}
				notFoundContent={fetching ? <Spin size="small" /> : null}
				searchValue={this.state.searchTerm}
				onKeyDownCapture={this.handleKeyDown}
				onKeyPress={(e) => {
					if (e.key === 'Enter') e.preventDefault();
				}}
				onSearch={(currentValue) => {
					this.showScrollBarOnTop();
					this.setState({ searchTerm: currentValue });
				}}
				onSelect={(currentValue, node) => {
					this.setState({ searchTerm: '' });
					if (this.props.isEditableCell) {
						this.props.onSelectEditableCell(node);
					}
				}}
				onFocus={() => {
					this.showScrollBarOnTop();
				}}
				onDropdownVisibleChange={(open) => {
					if (!open) {
						this.setState({ searchTerm: '' });
						this.buildAllOptions = false;
					} else {
						// prepare data for the popup
						this.onLoadData();
					}
				}}
				onChange={onChange}
				treeData={sortReferenceOptions(options, searchTerm, sortFunction)}
				treeNodeFilterProp="text"
				filterTreeNode={(inputValue, treeNode) => {
					if (!inputValue) return true;
					const normalizedTerm = normalizedValue(inputValue)
						.replace(/[.*+?^${}()|[\]\\]/g, '.')
						.split(' ');

					const searchRegexp = normalizedTerm.map((v) => new RegExp(v, 'i'));
					const t = normalizedValue(treeNode?.text);
					return searchRegexp.filter((v) => v.test(t)).length === searchRegexp.length;
				}}
				dropdownRender={
					showDrawer &&
					formComponents[currConfigRecord] &&
					((menu) => (
						<div>
							{fetching ? <Spin size="small" /> : menu}
							<Divider style={{ margin: '4px 0' }} />
							{this.access && (
								<Button
									type="link"
									style={{
										color: '#01bce8',
									}}
									icon={<FontAwesomeIcon icon={faPlus} className="mr-1" />}
									onClick={() =>
										this.setState({
											drawerVisible: true,
										})
									}
								>
									{this.props.t(`model.${currConfigRecord}.actions.create_new`)}
								</Button>
							)}
							<Button
								type="link"
								style={{
									color: '#01bce8',
								}}
								icon={<FontAwesomeIcon icon={faSyncAlt} className="mr-1" />}
								onClick={() => {
									this.fetch(true);
									if (this.props.changefly) this.props.changefly();
								}}
							>
								Refresh list
							</Button>
						</div>
					))
				}
			/>
		);

		const RecordForm = formComponents[currConfigRecord];

		const DrawerComponent = RecordForm && (
			<Drawer
				title={this.props.t(`model.${currConfigRecord}.actions.create_new`)}
				width={720}
				onClose={() => this.setState({ drawerVisible: false })}
				open={this.state.drawerVisible}
				bodyStyle={{ paddingBottom: 80, paddingLeft: 20, paddingRight: 20 }}
			>
				<RecordForm
					isPopUp
					onPopUpCreate={this.onPopUpCreate.bind(this)}
					pushNotification={pushNotification || null}
					isReferenceListLoaded={isReferenceListLoaded || null}
					setReferenceListValues={setReferenceListValues || null}
					getReferenceListValues={getReferenceListValues || null}
					recordType={currConfigRecord}
				/>
			</Drawer>
		);

		if (showOpenReference) {
			return (
				<Input.Group className="d-flex" style={{ width: style?.width }}>
					{TreeComponent}
					<Button
						size={this.props.size || 'middle'}
						disabled={!this.props.value}
						icon={<FontAwesomeIcon icon={faSearch} className="mr-1" size="sm" />}
						onClick={this.goToReference.bind(this)}
						className="reference-decoration"
					/>
					{showDrawer && DrawerComponent}
				</Input.Group>
			);
		}
		return (
			<>
				<Input.Group className="d-flex" style={{ width: style?.width }}>
					{TreeComponent}
				</Input.Group>
				{showDrawer && DrawerComponent}
			</>
		);
	}
}
export default withTranslation()(ReferenceSelect);
export const MemoizedReferenceSelect = React.memo(ReferenceSelect);
