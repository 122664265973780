// helpers
import DataService from 'src/utils/DataService';
import ConfigReport from 'ui/modules/Reporting/config/reporting.config';
import { INotebook } from 'ui/modules/Reporting/types/interfaces/report.interface';
import axios from 'axios';
import snakeCase from 'lodash';

const getNotebookContent = (notebook: INotebook) => {
	const { cells } = notebook.content;
	return {
		...notebook.content,
		cells: (cells || []).map((cell) => {
			const { cell_type, source, metadata, outputs } = cell;
			return {
				cell_type,
				source,
				metadata,
				outputs,
			};
		}),
	};
};

export const open = async (id: string, refresh: boolean) => {
	const { data } = await new DataService({
		url: `${ConfigReport.url}/${id}/open`,
		urlParams: '',
	}).post({ refresh });

	if (data?.result?.notebook_url) {
		const notebookContent = await axios.get(data.result.notebook_url);
		if (notebookContent.status === 200) {
			data.result.content = notebookContent.data;
		}
	}

	return {
		data,
	};
};

export const close = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/force-close`,
		urlParams: '',
	}).post({});
};

export const publish = async (id: string, addToStorage: boolean) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/export${addToStorage ? '?storage=true' : ''}`,
		urlParams: '',
	}).post({});
};

export const save = async (id: string, payload: INotebook) => {
	const { data } = await new DataService({
		url: `${ConfigReport.url}/${id}/save`,
		urlParams: '',
	}).put({});

	if (!data?.result?.uploadUrl) {
		throw new Error('Upload URL not found');
	}

	const { uploadUrl } = data.result;
	await axios.put(uploadUrl, getNotebookContent(payload));
};

export const saveAndClose = async (id: string, payload: INotebook) => {
	await save(id, payload);
	await close(id);
};

export const exportNotebook = async (name: string, payload: INotebook) => {
	// save notebook content into a file
	const blob = new Blob([JSON.stringify(getNotebookContent(payload))], { type: 'application/octet-stream' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = `${snakeCase(name)}.ipynb`;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	window.URL.revokeObjectURL(url);
};

export default {
	open,
	close,
	publish,
	save,
	saveAndClose,
	exportNotebook,
};
