export default {
	name: 'Distribution List',
	name_plural: 'Distribution Lists',
	new_record_title: 'New Distribution List',
	tabs: {
		summary: 'Summary',
		usages: 'Usages',
	},
	actions: {
		create_new: 'New Distribution List',
		view_distribution_list: 'View Distribution List',
	},
	success: {
		create_distribution_list: 'Distribution List created successfully',
		update_distribution_list: 'Distribution List updated successfully',
		delete_distribution_list: 'Distribution List(s) deleted successfully',
	},
	errors: {
		retrieve_all_distribution_lists: 'An error occurred while retrieving distribution lists',
		retrieve_distribution_list: 'An error occurred while retrieving distribution list data',
		create_distribution_list: 'An error occurred while creating a new distribution list',
		update_distribution_list: 'An error occurred while updating this distribution list',
		delete_distribution_list: 'An error occurred while deleting distribution list(s)',
	},
	labels: {
		members_list: 'List of Distribution Members',
	},
	descriptions: {
		members_description:
			'These members will be receiving all reports having this distribution list for email delivery.',
	},
	validation: {
		name: 'Description Field is Required',
	},
};
