export default {
	name: 'Report',
	name_plural: 'Reports',
	new_record_title: 'Create Report',
	types: {
		markdown: 'Markdown',
		notebook: 'Notebook',
		file: 'File Report',
		code: 'Code',
		markdown_plural: 'Markdowns',
		code_plural: 'Codes',
	},
	labels: {
		name: 'Name',
		schedule: 'Schedule',
		schedule_notebook: 'Export Notebook to Files Storage',
		type: 'Type',
		report: 'Report',
		status: 'Status',
		path: 'Path',
		kernel_id: 'kernel_id',
		session_id: 'session_id',
		created_at: 'Created At',
		updated_at: 'Updated At',
		closed_connection: 'Connection Closed',
		opened_by: 'Opened By',
		created_by: 'Created By',
		file: 'File',
		distribution_lists: 'Distribution Lists',
		deliveries: 'Deliveries',
		email_body: 'Email Body',
		markdown_guide: 'Markdown Syntax Guide',
		attachments: 'Attachments',
		drawer_title: 'File Picker',
		send_date: 'Send Date',
		send_immediately: 'Send Immediately',
	},
	dialog: {
		close_title: 'Close Notebook',
		close_dialog:
			'Are you sure you want to close the notebook? Please make sure to save your work before closing to avoid losing your changes.',
		delete_cell: 'Are you sure you want to delete this cell?',
		import_title: 'Import Notebook',
		import_description: 'Please select a notebook file to import.',
		import_hint: 'You can only import .ipynb files.',
		publish_title: 'Publish Notebook',
		publish_dialog:
			'You can either export the notebook as a file or export it as a file and add it to the files section. Which one do you want to do?',
	},
	actions: {
		create_new: 'Create Report',
		get_one: 'Get Report',
		update: 'Update Report',
		delete_report: 'Delete Report',
		delete: 'Delete',
		delete_markdown: 'Delete Markdown',
		save: 'Save',
		save_notebook: 'Save Notebook',
		run: 'Run Notebook',
		copy: 'Copy',
		open: 'Open Notebook',
		reopen: 'Reload Notebook',
		open_anyway: 'Open Anyway',
		close: 'Close',
		close_notebook: 'Close Notebook',
		open_notebook: 'Open Notebook',
		save_and_close: 'Save and Close',
		save_and_close_notebook: 'Save and Close Notebook',
		import: 'Import Notebook',
		publish: 'Publish',
		publish_and_save: 'Publish and Add to Files',
		publish_notebook: 'Publish Notebook',
		save_all: 'Save All',
		cancel: 'Cancel',
		download: 'Download',
		add_attachment: 'Add Attachment',
		add_files: 'Add Files',
		view: 'View',
		view_attachment: 'Click To View',
	},
	errors: {
		access_denied: 'Access Denied',
		error_list_users: 'Users list is empty',
		error_access_notebook: 'Cannot access this notebook at this moment.',
		error_already_open_notebook_recently: 'This notebook is being edited by: ',
		error_already_open_notebook: 'This notebook is already opened by: ',
		error_close_notebook: 'This notebook cannot be closed.',
		error_connection: 'There are some problems with your WS URL.',
		error_create_report: 'An error has occurred when creating the report.',
		error_delete_report: 'An error has occurred when deleting the report.',
		error_distribution_list: 'An error occurred when retrieving the distribution list.',
		error_get_report: 'An error has occurred when getting the report details.',
		error_occurred_notebook: 'An error occurred while opening the notebook.',
		error_occurred: 'An error occurred when retrieving reports.',
		error_open_notebook: 'This notebook cannot be opened.',
		error_publish_notebook: 'An error has occurred when publishing the notebook.',
		error_saved_notebook: 'An error has occurred when saving the notebook.',
		error_update_report: 'An error has occurred when updating the report.',
		not_authorized: "You're not authorized to access this report.",
		report_already_opened: 'Report is already opened.',
		resource_not_found: 'Report not found.',
		warning_run_cell: 'Cannot run this notebook at this moment.',
	},
	success: {
		copied: 'Copied!',
		created: 'Report created successfully.',
		updated: 'Report updated successfully.',
		deleted: 'Report deleted successfully.',
		closed: 'Notebook closed successfully.',
		saved_and_closed: 'Notebook saved and closed successfully.',
		saved: 'Notebook saved successfully.',
		published: 'Notebook published successfully.',
	},
	tabs: {
		summary: 'Summary',
		distribution_analysis: 'Distribution Analysis',
	},
	sections: {
		general: 'General',
		attachments: 'Attachments',
		delivery_list: 'Deliveries',
		distribution_list: 'Distribution list',
	},
	placeholders: {
		distribution_lists: 'Select Distribution Lists',
		attachments: 'No attachments available',
		email_body: 'Enter Email Body Here (You Can Use Markdown Language)',
	},
	messages: {
		no_ex_list: 'Has no exchange list',
		validate_send_date: 'The send date must be in the future. Please change the send date.',
	},
	required: {
		name: 'Name field is required',
		type: 'Type field is required',
		date: 'Date field is required',
		timezone: 'Timezone field is required',
		distribution_lists: 'Distribution Lists field is required',
		email_body: 'Email Body field is required',
		attachments: 'Attachment field is required',
		send_date: 'Send Date field is required',
	},
};
