// Dependencies
import React from 'react';
import { map } from 'lodash';
import { Options } from 'react-toast-notifications';
import { AxiosError } from 'axios';
// Ant Design & Icon
import { Space, Tag } from 'antd';
import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
// types
import { ILabel } from 'ui/modules/Core/types/interfaces/label.interface';
import { ILabelsContext } from 'src/types/interfaces/route.interface';
// Services
import LabelService from 'ui/modules/Core/services/label.service';
// Helpers
import { pushNotification } from 'src/utils/PushNotification';
// Style
import 'src/ui/modules/Core/components/Labels/Label.sass';
// constants
const defaultColors = ['#ffffff', 'white'];

export const getIsPrivate = (event: any) => {
	if (event?.data?.organization_id) {
		return <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '14px' }} />;
	} else {
		return <CloseCircleOutlined className="icon-error" style={{ fontSize: '14px' }} />;
	}
};

export const getColor = (event: any) => {
	return <span className="span-circle" style={{ backgroundColor: event?.data?.color || 'transparent' }}></span>;
};

// convert Hex color to rgb color
const hexToRgbA = (hex: string, alpha: number) => {
	let r = parseInt(hex.slice(1, 3), 16);
	let g = parseInt(hex.slice(3, 5), 16);
	let b = parseInt(hex.slice(5, 7), 16);

	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// get labels to display into ag grid col
export const getLabels = (labels: string[] | ILabel[]) => {
	return <Space className="tags">{map(labels, (el: ILabel | string) => getOneLabel(el))}</Space>;
};

// get label as tag with color
export const getOneLabel = (label: ILabel | string) => {
	const isString = typeof label === 'string';
	const name = isString ? label : label?.name;
	const color = isString ? null : label?.color;
	if (isString || !color || defaultColors.includes(color)) {
		return (
			<Tag key={isString ? name : label.id} color="default">
				{name}
			</Tag>
		);
	}
	return (
		<div
			key={label?.id}
			style={{
				width: 'max-content',
				height: 'auto',
				backgroundColor: hexToRgbA(color, 0.1),
				color: color,
				border: `1px solid ${color}`,
				fontSize: 12,
				padding: '0px 7px',
				borderRadius: 3,
				lineHeight: 1.5715,
				marginRight: 8,
			}}
		>
			{name}
		</div>
	);
};

export const contextOrAPIGetLabels = async (
	addToast: Options,
	translate: (key: string) => string,
	getLabelsListValues: ILabelsContext['getLabelsListValues'],
	setLabelsListValues: ILabelsContext['setLabelsListValues'],
	isLabelsListLoaded: ILabelsContext['isLabelsListLoaded'],
	recordType: string = 'all',
	cache: boolean = true
): Promise<ILabel[]> => {
	if (isLabelsListLoaded(recordType) && cache) {
		return getLabelsListValues(recordType);
	}
	try {
		const response = await LabelService.getInstance(recordType === 'all' ? null : recordType).getAll();
		const labels = response?.data?.result || [];
		setLabelsListValues(recordType, labels);
		return labels;
	} catch (err) {
		const error = err as AxiosError;
		pushNotification(
			addToast,
			translate('model.label.name_plural'),
			'error',
			error?.response?.data?.error?.message || translate('model.label.errors.error_occured')
		);
		return [];
	}
};
