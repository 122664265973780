import React from 'react';
import { fileTrackingColDef } from 'src/utils/AGGridFilters/FilterParams';
import { IMember } from 'ui/modules/Reporting/types/interfaces/member.interface';
export const recordType = 'member';

export const MemberColumns = [
	{
		headerName: 'First Name',
		field: 'first_name',
		minWidth: 150,
	},
	{
		headerName: 'Last Name',
		field: 'last_name',
		minWidth: 150,
	},
	{
		headerName: 'Email',
		field: 'email',
		minWidth: 150,
	},
	{
		headerName: 'Created by',
		field: 'created_by_user',
		minWidth: 150,
		type: 'reference',
		referenceType: 'user',
	},
	{
		headerName: 'Updated by',
		field: 'updated_by_user',
		minWidth: 150,
		type: 'reference',
		referenceType: 'user',
	},
	{
		headerName: 'Created at',
		field: 'created_at',
		type: 'datetime',
		minWidth: 120,
	},
	{
		headerName: 'Updated at',
		field: 'updated_at',
		type: 'datetime',
		minWidth: 120,
	},
];

export const distributionMembersColumns = [
	{
		title: 'First Name',
		dataIndex: 'first_name',
		key: 'first_name',
		defaultSortOrder: 'descend',
		sorter: (a: IMember, b: IMember) => a.first_name.localeCompare(b.first_name),
		render: (record: string) => <small>{record || '_'}</small>,
	},
	{
		title: 'Last Name',
		dataIndex: 'last_name',
		key: 'last_name',
		defaultSortOrder: 'descend',
		sorter: (a: IMember, b: IMember) => a.last_name.localeCompare(b.last_name),
		render: (record: string) => <small>{record || '_'}</small>,
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		defaultSortOrder: 'descend',
		sorter: (a: IMember, b: IMember) => a.email.localeCompare(b.email),
		render: (record: string) => <small>{record || '_'}</small>,
	},
];

export const memberAssociationsColDef = [
	{
		headerName: 'Distribution List',
		field: 'name',
		flex: 1,
		...fileTrackingColDef,
	},
	{
		headerName: 'Created at',
		field: 'created_at',
		cellRenderer: 'dateCellRenderer',
		type: 'datetime',
		flex: 1,
		...fileTrackingColDef,
	},
];
